import React, { useCallback, useContext, useRef } from "react";

import {
  CalciteButton,
  CalciteLink,
  CalciteNavigation,
  CalciteNavigationLogo,
  CalciteNavigationUser,
  CalcitePanel,
  CalcitePopover,
  CalciteSwitch,
  CalciteLabel
} from "@esri/calcite-components-react";
import { AppContext } from "../AppContext";
import { signOut } from "../services/portal";

const HeaderNav = () => {
  const { oAuthAppId, agPortal,appTheme, setAppTheme } = useContext(AppContext);

  const onClick = (e) => {
    console.log(e);
  };

  const ref = useRef();

  return (
    <CalciteNavigation slot="header" onCalciteNavigationActionSelect={onClick}>
      <CalciteNavigationLogo
        slot="logo"
        heading="Forest Stewardship Council"
        description="Load your FSC certified area"
      ></CalciteNavigationLogo>
      
      {agPortal ? (
        <>
          <CalcitePopover
            slot="content-end"
            placement="bottom"
            offsetDistance={0}
            offsetSkidding={-10}
            referenceElement="user-popover"
            {...{ autoClose: true }}
            {...{ pointerDisabled: true }}
            {...{ closable: false }}
          >
            <CalcitePanel className="d-flex user-profile-window">
              <CalciteButton
                slot="footer"
                scale="l"
                width="half"
                iconEnd="question"
                appearance="outline-fill"
                onClick={() =>
                  window.open(
                    "https://fsc.org",
                    "_blank"
                  )
                }
              >
                FSC
              </CalciteButton>
              <CalciteButton
                scale="l"
                width="half"
                slot="footer"
                onClick={() =>
                  signOut(
                    { portalUrl: agPortal.url, oauthAppId: oAuthAppId },
                    window.location.href
                  )
                }
              >
                Sign out
              </CalciteButton>
              <div className="d-flex flex-fill p-3">
                <div
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{
                    flex: 1,
                  }}
                >
                  {/* <div> */}
                  
                  {/* </div> */}
                  <div className="mt-2 lh-lg">
                    <span className="fs-6 fw-bold">
                      {agPortal.user.fullName}
                    </span>
                  </div>
                  <div>
                    <span className="fw-light">{agPortal.user.username}</span>
                  </div>
                </div>
                
              </div>
            </CalcitePanel>
          </CalcitePopover>
          <CalciteNavigationUser
            id="user-popover"
            style={{
              borderLeft: "1px solid var(--calcite-ui-border-3)",
            }}
            slot="user"
            thumbnail={agPortal.user.thumbnailUrl}
            fullName={agPortal.user.fullName}
            username={agPortal.user.username}
          ></CalciteNavigationUser>
        </>
      ) : (
        <CalciteNavigationUser
          style={{
            borderLeft: "1px solid var(--calcite-ui-border-3)",
          }}
          slot="user"
          fullName="Loading ..."
        ></CalciteNavigationUser>
      )}
    </CalciteNavigation>
  );
};

export default HeaderNav;
