import React, { useContext, useEffect, useRef } from "react";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import Search from "@arcgis/core/widgets/Search";
import Expand from "@arcgis/core/widgets/Expand";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery.js";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import { AppContext } from "../AppContext";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

const EsriMap = () => {
  const {
    mapView,
    setMapView,
    shouldFilterOnMapMove,
    mapHandle,
    setMapHandle,
    setMapExtent,
    mapExtent,
  } = useContext(AppContext);

  const viewDiv = useRef(null);

  useEffect(() => {
    if (viewDiv) {
      const map = new Map({
        basemap: "gray-vector",
      });

      const view = new MapView({
        map,
        zoom: 2,
        center: [0, 0],
        container: viewDiv.current,
      });

      setMapView(view);

      const searchWidget = new Search({
        view: view,
      });

      const searchExpand = new Expand({
        content: searchWidget,
        view,
      });

      const basemapGallery = new BasemapGallery({
        view: view,
        container: document.createElement("div")
      });

      const bgExpand = new Expand({
        view: view,
        content: basemapGallery
      });

      reactiveUtils.watch(
        () => [view.map.allLayers.map((layer) => layer.visible)],
        () => {
          const visibleLayer = view.map.allLayers.find(
            (layer) => layer.type === "feature" && layer.visible
          );

          if (!visibleLayer) {
            return;
          }
        }
      );

      view.ui.move("zoom", "top-left");
      view.ui.add(searchExpand, "top-left");
      view.ui.remove("attribution");
      view.ui.add(bgExpand, "top-left");
    }
  }, []);

  useEffect(() => {
    if (!mapView) {
      return;
    }

    if (mapExtent) {
      mapView.extent = mapExtent;
      mapView.goTo(mapView.extent);
    }
  }, [mapView, mapExtent]);

  useEffect(() => {
    if (!mapView) {
      return;
    }

    if (!mapHandle) {
      const handle = reactiveUtils.watch(
        () => [mapView.stationary, mapView.extent, mapView.scale],
        ([stationary, extent, scale], [wasStationary]) => {
          if (stationary) {
            if (shouldFilterOnMapMove) {
              console.log(extent);
            } else {
              handle.remove();
            }
          }
          return "";
        }
      );
      setMapHandle(handle);
    } else {
      if (shouldFilterOnMapMove) {
        const handle = reactiveUtils.watch(
          () => [mapView.stationary, mapView.extent, mapView.scale],
          ([stationary, extent, scale], [wasStationary]) => {
            if (stationary) {
              if (shouldFilterOnMapMove) {
                console.log(extent);
              } else {
                handle.remove();
              }
            }
            return "";
          }
        );
      } else {
        mapHandle.remove();
      }
    }
  }, [mapView, shouldFilterOnMapMove]);

  return (
    <>
      <div slot="header" style={{ flex: "0 0 5%" }}>
        <h3>Review Your FSC Certified Area</h3>
        <p style={{ fontSize: "18px" }}>
          Please check you FSC certified area whether it's in the right location or not.
          Re-upload your data if there is any error.
        </p>
      </div>
      <div ref={viewDiv} style={{ height: "600px"}}>
        <div ></div>
      </div>
    </>
  );
};

export default EsriMap;
