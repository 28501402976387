import React, { createContext, useState } from "react";
import { useImmer } from "use-immer";
import { setAutoFreeze } from "immer";

setAutoFreeze(false);

export const AppContext = createContext();

const AppContextProvider = (props) => {
  const [oAuthAppId, setOAuthAppId] = useState(null);
  const [credential, setCredential] = useState(null);
  const [agPortal, setAgPortal] = useState(null);
  const [appTheme, setAppTheme] = useState("light");
  const [mapHandle, setMapHandle] = useState(null);
  const [mapExtent, setMapExtent] = useState(null);
  const [showMapPreview, setShowMapPreview] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [functionStep, setFunctionStep] = useState(null);
  const [fscId, setFscId] = useState(null);
  const [noticeOpen, setNoticeOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [publicVisibleOpen, setPublicVisibleOpen] = useState(false);
  const [loadingVisible, setLoadingVisible] = useState(false);

  const [mapView, setMapView] = useState(null);
  const [reportShape, setReportShape] = useState(null);

  return (
    <AppContext.Provider
      value={{
        oAuthAppId,
        setOAuthAppId,

        credential,
        setCredential,
        agPortal,
        setAgPortal,

        mapView,
        setMapView,

        appTheme,
        setAppTheme,

        mapHandle,
        setMapHandle,

        mapExtent,
        setMapExtent,

        showMapPreview,
        setShowMapPreview,

        showReport,
        setShowReport,

        fscId,
        setFscId,
        loadingVisible,
        setLoadingVisible,
        reportShape,
        setReportShape,

        functionStep,
        setFunctionStep,

        noticeOpen,
        setNoticeOpen,
        privacyOpen,
        setPrivacyOpen,
        publicVisibleOpen,
        setPublicVisibleOpen,
        termsOpen,
        setTermsOpen,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
