import React, {
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import { AppContext } from "../AppContext";
import FeatureSet from "@arcgis/core/rest/support/FeatureSet";
import { useDropzone } from "react-dropzone";
import { CalciteNotice } from "@esri/calcite-components-react";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const AttachmentZone = {
  height: "250px",
  width: "100%",
  backgroundColor: "#ddd",
  border: "1px dashed rgb(187, 186, 186)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  font: "16px",
};

function UploadData() {
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageTitle, setMessageTitle] = useState(null);

  useEffect(() => {
    if (messageOpen) {
      const timer = setTimeout(() => {
        setMessageOpen(false);
        setMessage(null);
        setMessageTitle(null);
      }, 10000); // Change this to the number of milliseconds you want the timer to run for

      return () => clearTimeout(timer); // This will clear the timer when the component unmounts or when messageOpen changes
    }
  }, [messageOpen]);

  const {
    agPortal,
    setMapExtent,
    mapView,
    setShowMapPreview,
    fscId,
    setFunctionStep,
    setReportShape,
    setLoadingVisible,
  } = useContext(AppContext);

  // Create a symbol for drawing the line
  const lineSymbol = {
    type: "simple-line", // autocasts as SimpleLineSymbol()
    color: [226, 119, 40],
    width: 4,
  };

  const polygonSymbol = {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [0, 100, 0, 0.6],
    outline: {
      color: [255, 255, 255],
      width: 1,
    },
  };

  const pointSymbol = {
    type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
    style: "circle",
    color: [226, 119, 40],
    size: 12,
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    mapView.graphics.removeAll();
    setReportShape(null);
    if (rejectedFiles.length > 0) {
      //Get the First Rejected File
      var rejectedFile = rejectedFiles[0];
      //check the code
      if (rejectedFile.errors[0].code === "file-too-large") {
        setMessageOpen(true);
        setMessage("Please check file size, only 200MB is allowed.");
        setMessageTitle("File Size Limit Exceeded");
        console.log("Rejected files: ", rejectedFiles);
      } else if (rejectedFile.errors[0].code === "file-invalid-type") {
        setMessageOpen(true);
        setMessage("Only *.zip files will be accepted.");
        setMessageTitle("Incorrect File Type");
      } else if (rejectedFile.errors[0].code === "too-many-files") {
        setMessageOpen(true);
        setMessage("Only one file can be uploaded at a time.");
        setMessageTitle("Too Many Files");
      } else {
        setMessageOpen(true);
        setMessage("Please check the file and try again.");
        setMessageTitle("Error");
      }
      return;
    }
    setLoadingVisible(true);

    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filetype", "shapefile");
    var params = {
      name: "uploadTest",
      targetSR: { wkid: 102100, latestWkid: 3857 },
      maxRecordCount: 1000,
      enforceInputFileSizeLimit: false,
      enforceOutputJsonSizeLimit: false,
      generalize: false,
      maxAllowableOffset: 0,
      reducePrecision: false,
      numberOfDigitsAfterDecimal: 10,
    };
    formData.append("publishparameters", JSON.stringify(params));
    formData.append("f", "json");
    formData.append("token", agPortal.credential.token);
    console.log(`${agPortal.url}/sharing/rest/content/features/generate`);
    fetch(`${agPortal.url}/sharing/rest/content/features/generate`, {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.ok) {
        setLoadingVisible(false);
        return response.json().then((data) => {
          //Check for error
          console.log(data);
          if (data.error) {
            setMessageOpen(true);
            setMessage(data.error.message);
            setMessageTitle("Error");
            setReportShape(null);
            setFunctionStep("upload");
            return;
          }

          var featureSet = FeatureSet.fromJSON(
            data.featureCollection.layers[0].featureSet
          );

          var geo = [];
          //Add the Feature to the Map
          for (const feature of featureSet.features) {
            if (feature.geometry.type === "polygon") {
              geo.push(feature.geometry.rings[0]);
              feature.symbol = polygonSymbol;
              mapView.graphics.add(feature);
              setReportShape({ rings: geo });

              setMapExtent(
                data.featureCollection.layers[0].layerDefinition.extent
              );
              setShowMapPreview(false);
              setFunctionStep("preview");
            } else {
              setMessageOpen(true);
              setMessage("Only polygon shapefiles are supported.");
              setMessageTitle("Please upload a polygon shapefile");
            }
          }
        });
      }
    });
  });

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      maxSize: 209715200, // 200 MB in bytes
      accept: {
        "application/zip": [],
        "application/x-zip-compressed": [],
      },
      maxFiles: 1,
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <>
      <div slot="header" style={{ flex: "0 0 10%" }}>
        <h3>
          Upload Shapefile for FSC License Code: <b>{fscId}</b>
        </h3>
        <p style={{ fontSize: "18px" }}>
          Please upload your zipped shapefile here. Either click or drag and
          drop your file to the following box.
        </p>
      </div>
      <div slot="content">
        <div slot="content" style={AttachmentZone}>
          <div {...getRootProps()} style={{ style }}>
            <input {...getInputProps()} />
            <p style={{ fontSize: "17px" }}>
              Drag 'n' drop zipped shapefile here, or click here to select the
              file.
            </p>
            <em>(Only *.zip files will be accepted)</em>
          </div>
        </div>
      </div>
      <br></br>
      <h5>*Note for Small holders and Group Certificate holders*</h5>

      <p style={{ fontSize: "15px" }}>
        Please upload a "Polygon" shapefile here for the FSC certified area. A
        digitizing functionality will come soon for those who does not have any
        shapefile for FSC certified area right now.<br></br>
        The digitization functionality will give the user to digitize their FSC
        certified area location as a "Point" in a web map.
      </p>

      <CalciteNotice
        {...(messageOpen ? { open: true } : { open: undefined })}
        icon="exclamation-mark-triangle"
      >
        <div slot="title">
          <b>{messageTitle ? messageTitle : "Incorrect File Type"}</b>
        </div>
        <div slot="message">
          {message ? message : "Only polygon shapefiles are supported."}
        </div>
      </CalciteNotice>
    </>
  );
}

export default UploadData;
