import { useRef, useContext, useState } from "react";
import {
  CalciteAlert,
  CalciteButton,
  CalciteCheckbox,
  CalciteLabel,
  CalciteModal,
} from "@esri/calcite-components-react";

import { AppContext } from "../AppContext";

const Report = () => {
  const reportRef = useRef(null);

  const [allChecked, setAllChecked] = useState(false);
  const [publicVisibility, setPublicVisibility] = useState(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const {
    fscId,
    agPortal,
    reportShape,
    setNoticeOpen,
    setPrivacyOpen,
    setTermsOpen,
    setPublicVisibleOpen,
    setFunctionStep,
  } = useContext(AppContext);

  function submitForm(event) {
    event.preventDefault();

    var adds = [
      {
        attributes: { cert_details: fscId, public_map: publicVisibility, privacy_st: isPrivacyChecked, terms_st: isTermsChecked, user_id: agPortal.user.username},
        geometry: reportShape,
      },
    ];
    const url = process.env.REACT_APP_FEATURE_SERVICE + "/applyEdits";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `adds=${encodeURIComponent(JSON.stringify(adds))}&f=json&token=${
        agPortal.credential.token
      }`,
    };

    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        setNoticeOpen(true);
        setFunctionStep("thanks");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  function handleCheckboxChange() {
    console.log("handleCheckboxChange");
    // Get all checkboxes
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    // Check if all checkboxes are checked
    const allChecked = Array.from(checkboxes).every(
      (checkbox) => checkbox.checked
    );

    // Update the state variable
    setAllChecked(allChecked);
  }

  return (
    <>
      <div slot="header" style={{ flex: "0 0 5%", textAlign: "center" }}>
        <h3>
          Please read and accept the privacy and terms for the FSC License Code:{" "}
          <b>{fscId}</b>
        </h3>
        <p style={{ fontSize: "18px" }}>
          You need to read the relevant privacy statements and accept it before
          submit your data. The acceptance of privacy policy and
          terms are mandatory.<br></br>
          If you want to make your FSC certified area visible to the public map
          then read and accept the Public Visibility Statement.
        </p>
        <br></br>
      </div>
      <div style={{ margin: "0 25%", fontSize: "18px" }}>
        <form onSubmit={submitForm}>
          <fieldset>
            <label for="privacy">
              <h4>Privacy Statement</h4>
            </label>
            <p>
              Please read and accept the following link of privacy statement
            </p>
            <p>
              <CalciteButton
                appearance="inline"
                color="blue"
                onClick={() => setPrivacyOpen(true)}
              >
                Privacy Policy
              </CalciteButton>
            </p>
            <CalciteLabel layout="inline">
              <CalciteCheckbox
                id="privacy"
                name="privacy"
                value="privacy"
                type="checkbox"
                onCalciteCheckboxChange={(event) => {
                  handleCheckboxChange();
                  setIsPrivacyChecked(event.target.checked);
                }}
              ></CalciteCheckbox>
              Accept
            </CalciteLabel>

            {/* <label for="terms">
              <h4>Terms and Conditions</h4>
            </label>
            <p>
              Please read and accept the following link of terms and condition
            </p>
            <p>
              <CalciteButton
                appearance="inline"
                color="blue"
                onClick={() => setTermsOpen(true)}
              >

                Terms and Conditions
              </CalciteButton>
            </p>
 
            <CalciteLabel layout="inline">
              <CalciteCheckbox
                id="terms"
                name="terms"
                value="terms"
                type="checkbox"
                onCalciteCheckboxChange={(event) => {
                  handleCheckboxChange();
                  setIsTermsChecked(event.target.checked);
                }}
              ></CalciteCheckbox>
              Accept
            </CalciteLabel>
            */}
            <br></br>

            <label for="public_visibility">
              <h4>Public Visibility Statement</h4>
            </label>
            <p>
              Please read and accept the following link of public visibility
              statement if you want to make your FSC certified area publicly
              visible
            </p>
            <p>
              <CalciteButton
                appearance="inline"
                color="blue"
                onClick={() => setPublicVisibleOpen(true)}
              >
                Public Visibility Statement
              </CalciteButton>
            </p>
            <CalciteLabel layout="inline">
              <CalciteCheckbox
                id="public"
                name="public"
                value="accept"
                type="checkbox"
                onCalciteCheckboxChange={(event) => {
                  handleCheckboxChange();
                  setPublicVisibility(event.target.checked);
                }}
              ></CalciteCheckbox>
              Accept
            </CalciteLabel>
            <div style={{ textAlign: "right" }}>
              <CalciteButton
                type="submit"
                value="Submit"
                disabled={isPrivacyChecked ? undefined : true}
              >
                Submit
              </CalciteButton>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
};

export default Report;
