import esriConfig from "@arcgis/core/config";
import esriRequest from "@arcgis/core/request";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import Portal from "@arcgis/core/portal/Portal";
import PortalItem from "@arcgis/core/portal/PortalItem";
import PortalItemResource from "@arcgis/core/portal/PortalItemResource";
import PortalUser from "@arcgis/core/portal/PortalUser";
import { addProxyRule } from "@arcgis/core/core/urlUtils";

import { cleanUrl, enforceSsl } from "../common/utils";

/**
 * Register OAuth info for this app with the JavaScript API.
 */
export async function registerOAuthInfo({ oauthAppId, portalUrl }, locale) {
  const info = new OAuthInfo({
    locale,
    appId: oauthAppId,
    portalUrl: cleanUrl(portalUrl),
  });

  IdentityManager.registerOAuthInfos([info]);
}

/**
 * Sign in the user and load their portal.
 */
export async function signInAndLoadPortal({ portalUrl }) {
  esriConfig.portalUrl = cleanUrl(portalUrl);
  const portal = new Portal({ authMode: "immediate" });
  await portal.load();
  return portal;
}

/**
 * Find the credential for the specified resource.
 */
export function findCredential(url) {
  return IdentityManager.findCredential(url);
}

/**
 * Sign out the user.
 * @param param0 app configuration object.
 * @param redirectTo URI to redirect the user to after sign out.
 */
export function signOut({ portalUrl, oauthAppId }, redirectTo) {
  const portalRestUrl = `${cleanUrl(portalUrl)}/sharing/rest`;
  const signOutUrl = enforceSsl(
    `${portalRestUrl}/oauth2/signout?client_id=${oauthAppId}&redirect_uri=${redirectTo}`
  );
  IdentityManager.destroyCredentials();
  window.location.replace(signOutUrl);
}
