import "@esri/calcite-components/dist/components/calcite-action-bar";
import "@esri/calcite-components/dist/components/calcite-button";
import "@esri/calcite-components/dist/components/calcite-shell";
import "@esri/calcite-components/dist/components/calcite-shell-center-row";
import "@esri/calcite-components/dist/components/calcite-shell-panel";
import "@esri/calcite-components/dist/components/calcite-panel";
import "@esri/calcite-components/dist/components/calcite-stepper";
import "@esri/calcite-components/dist/components/calcite-stepper-item";
import "@esri/calcite-components/dist/components/calcite-scrim";
import "@esri/calcite-components/dist/components/calcite-label";
import "@esri/calcite-components/dist/components/calcite-switch";
import "@esri/calcite-components/dist/components/calcite-list";
import "@esri/calcite-components/dist/components/calcite-list-item";
import "@esri/calcite-components/dist/components/calcite-input";
import "@esri/calcite-components/dist/components/calcite-navigation";
import "@esri/calcite-components/dist/components/calcite-navigation-logo";
import "@esri/calcite-components/dist/components/calcite-navigation-user";
import "@esri/calcite-components/dist/components/calcite-popover";
import "@esri/calcite-components/dist/calcite/calcite.css";
import "@esri/calcite-components/dist/components/calcite-accordion";
import "@esri/calcite-components/dist/components/calcite-accordion-item";

import "@esri/calcite-components/dist/components/calcite-action-group";
import "@esri/calcite-components/dist/components/calcite-action-pad";
import "@esri/calcite-components/dist/components/calcite-alert";
import "@esri/calcite-components/dist/components/calcite-avatar";
import "@esri/calcite-components/dist/components/calcite-block";
import "@esri/calcite-components/dist/components/calcite-block-section";
import "@esri/calcite-components/dist/components/calcite-fab";
import "@esri/calcite-components/dist/components/calcite-split-button";
import "@esri/calcite-components/dist/components/calcite-card";
import "@esri/calcite-components/dist/components/calcite-checkbox";
import "@esri/calcite-components/dist/components/calcite-chip";
import "@esri/calcite-components/dist/components/calcite-combobox";
import "@esri/calcite-components/dist/components/calcite-combobox-item";
import "@esri/calcite-components/dist/components/calcite-combobox-item-group";
import "@esri/calcite-components/dist/components/calcite-dropdown";
import "@esri/calcite-components/dist/components/calcite-dropdown-group";
import "@esri/calcite-components/dist/components/calcite-dropdown-item";
import "@esri/calcite-components/dist/components/calcite-filter";
import "@esri/calcite-components/dist/components/calcite-flow";
import "@esri/calcite-components/dist/components/calcite-flow-item";
import "@esri/calcite-components/dist/components/calcite-icon";
import "@esri/calcite-components/dist/components/calcite-inline-editable";
import "@esri/calcite-components/dist/components/calcite-input-number";
import "@esri/calcite-components/dist/components/calcite-input-message";
import "@esri/calcite-components/dist/components/calcite-link";
import "@esri/calcite-components/dist/components/calcite-list-item-group";
import "@esri/calcite-components/dist/components/calcite-pick-list";
import "@esri/calcite-components/dist/components/calcite-pick-list-group";
import "@esri/calcite-components/dist/components/calcite-pick-list-item";
import "@esri/calcite-components/dist/components/calcite-value-list";
import "@esri/calcite-components/dist/components/calcite-value-list-item";
import "@esri/calcite-components/dist/components/calcite-loader";
import "@esri/calcite-components/dist/components/calcite-modal";
import "@esri/calcite-components/dist/components/calcite-notice";
import "@esri/calcite-components/dist/components/calcite-pagination";
import "@esri/calcite-components/dist/components/calcite-input-date-picker";
import "@esri/calcite-components/dist/components/calcite-input-time-picker";
import "@esri/calcite-components/dist/components/calcite-progress";
import "@esri/calcite-components/dist/components/calcite-radio-button";
import "@esri/calcite-components/dist/components/calcite-radio-button-group";
import "@esri/calcite-components/dist/components/calcite-segmented-control";
import "@esri/calcite-components/dist/components/calcite-segmented-control-item";
import "@esri/calcite-components/dist/components/calcite-rating";
import "@esri/calcite-components/dist/components/calcite-select";
import "@esri/calcite-components/dist/components/calcite-option";
import "@esri/calcite-components/dist/components/calcite-option-group";
import "@esri/calcite-components/dist/components/calcite-slider";
import "@esri/calcite-components/dist/components/calcite-tabs";
import "@esri/calcite-components/dist/components/calcite-tab-nav";
import "@esri/calcite-components/dist/components/calcite-tab-title";
import "@esri/calcite-components/dist/components/calcite-tab";
import "@esri/calcite-components/dist/components/calcite-tile-select";
import "@esri/calcite-components/dist/components/calcite-tile-select-group";
import "@esri/calcite-components/dist/components/calcite-tile";
import "@esri/calcite-components/dist/components/calcite-tooltip";
import "@esri/calcite-components/dist/components/calcite-tree";
import "@esri/calcite-components/dist/components/calcite-tree-item";
import "@esri/calcite-components/dist/components/calcite-menu";
import "@esri/calcite-components/dist/components/calcite-menu-item";

import CookieConsent, { Cookies } from "react-cookie-consent";

import {
  CalciteAlert,
  CalciteButton,
  CalciteLoader,
  CalciteModal,
  CalciteNotice,
  CalcitePanel,
  CalciteShell,
  CalciteStepper,
  CalciteStepperItem,
} from "@esri/calcite-components-react";

import "./App.css";
import { useContext, useEffect } from "react";
import { AppContext } from "./AppContext";
import HeaderNav from "./components/HeaderNav";
import Report from "./components/Report";

import {
  findCredential,
  registerOAuthInfo,
  signInAndLoadPortal,
} from "./services/portal";
import UploadData from "./components/UploadData";
import EsriMap from "./components/EsriMap";

function App() {
  const {
    credential,
    setCredential,
    agPortal,
    setAgPortal,
    appTheme,
    setOAuthAppId,
    setShowMapPreview,
    setShowReport,
    setFscId,
    fscId,
    noticeOpen,
    setNoticeOpen,
    privacyOpen,
    setPrivacyOpen,
    termsOpen,
    setTermsOpen,
    publicVisibleOpen,
    setPublicVisibleOpen,
    setFunctionStep,
    functionStep,
    loadingVisible
  } = useContext(AppContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const appConfig = {
    oauthAppId: process.env.REACT_APP_OAUTH_APP_ID,
    portalUrl: process.env.REACT_APP_PORTAL_URL,
  };

  useEffect(() => {
    if (noticeOpen) {
      const timer = setTimeout(() => {
        setNoticeOpen(false);
      }, 10000); // Change this to the number of milliseconds you want the timer to run for

      return () => clearTimeout(timer); // This will clear the timer when the component unmounts or when noticeOpen changes
    }
  }, [noticeOpen]);

  useEffect(() => {
    setOAuthAppId(appConfig.oauthAppId);

    async function checkIt() {
      if (credential && agPortal) {
        return;
      }
      let portal;
      try {
        await registerOAuthInfo(appConfig, "en-US");
        portal = await signInAndLoadPortal(appConfig);
        // console.log(portal);
        setAgPortal(portal);

        const foundCredential = findCredential(appConfig.portalUrl);
        console.log(foundCredential);
        setCredential(foundCredential);

        // Get the fs_id query parameter
        const urlParams = new URLSearchParams(window.location.search);
        const fs_id = urlParams.get("fs_id");
        setFscId(fs_id);
        console.log(fs_id); // Log the fs_id to the console
      } catch (err) {
        console.log(err);
        return;
      }
    }
    checkIt();
  }, [
    agPortal,
    appConfig,
    credential,
    setAgPortal,
    setCredential,
    setOAuthAppId,
  ]);

  return (
    <CalciteShell className={`calcite-mode-${appTheme}`}>
      <HeaderNav />
      {fscId ? (
        <>
          <CalciteStepper numbered>
            <CalciteStepperItem
              heading="Upload FSC Certified Area"
              summary="Upload your data to the portal"
              {...(functionStep === "upload" ? { selected: true } : {})}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  backgroundColor: "#f1f8e8",
                  color: "#285c4d",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <div style={{ flex: "0 0 90%", justifyContent: "flex-start" }}>
                  <UploadData></UploadData>
                </div>
              </div>
            </CalciteStepperItem>
            <CalciteStepperItem
              heading="Review Your FSC Certified Area"
              summary="Review your data"
              {...(functionStep === "preview" ? { selected: true } : {})}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  backgroundColor: "#f1f8e8",
                  color: "#285c4d",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                <div style={{ flex: "0 0 90%", justifyContent: "flex-start" }}>
                  <EsriMap></EsriMap>
                </div>
                <div
                  style={{
                    flex: "0 0 10%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div style={{ textAlign: "right", padding: "10px" }}>
                    <CalciteButton
                      onClick={() => {
                        setFunctionStep("report");
                      }}
                    >
                      Next
                    </CalciteButton>
                  </div>
                </div>
              </div>
            </CalciteStepperItem>
            <CalciteStepperItem
              heading="Submit FSC Certified Area"
              summary="Finish your report"
              {...(functionStep === "report" ? { selected: true } : {})}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  backgroundColor: "#f1f8e8",
                  color: "#285c4d",
                  padding: "10px",
                }}
              >
                <div style={{ flex: "1 0 auto" }}>
                  <Report></Report>
                </div>
                <div
                  style={{
                    flex: "0 0 10%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                ></div>
              </div>
            </CalciteStepperItem>
            <CalciteStepperItem
              heading="Finish"
              summary="Finish your report"
              {...(functionStep === "thanks" ? { selected: true } : {})}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  backgroundColor: "#f1f8e8",
                  color: "#285c4d",
                  textAlign: "center",
                  padding: "10px",
                }}
              >
                <div style={{ flex: "1 0 auto" }}>
                  <div>
                    <h3>Thank you for submitting your FSC certified area.</h3>
                    <p style={{ fontSize: 18 }}>
                      Please contact us if you face any trouble during the submission of your data and provide feedbacks at the following email.
                    </p>
                    <p style={{ fontSize: 18 }}>Email: connect@fsc.org</p>
                    <br></br>
                    <p style={{ fontSize: 18 }}>
                      If you want to select another certificate and upload
                      FSC certified area for that certificate then click on the
                      following button "<b>Select Certificate</b>".
                    </p>
                    <a href={process.env.REACT_APP_FSC_CONNECT} rel="noopener noreferrer">
                      <CalciteButton>Select Certificate</CalciteButton>
                    </a>
                  </div>
                </div>
                <div
                  style={{
                    flex: "0 0 10%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                ></div>
              </div>
            </CalciteStepperItem>
          </CalciteStepper>

          <CalciteNotice
            {...(noticeOpen ? { open: true } : { open: undefined })}
            icon="file-zip"
            scale="l"
            closable="true"
            onClick={() => {
              setNoticeOpen(false);
            }}
          >
            <div slot="title">
              <b>Shapefile Uploaded Successfully</b>
            </div>
            <div slot="message">Thank you for your submission</div>
          </CalciteNotice>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              textAlign: "center",
              backgroundColor: "#f1f8e8",
              color: "#285c4d",
              padding: "20px",
              margin: "120px",
            }}
          >
            <h3>
              Please open <b>FSC Certified Area Submittal</b> application from the FSC Connect app
              palette
            </h3>

            <p style={{ fontSize: "18px", textAlign: "center" }}>
              <br></br>
              You need to login with your FSC Connect credentials to view this
              application in the FSC Connect App palette.<br></br>
              Please contact the following email address for any FSC Connect
              related queries.
              <br></br>
              <b>Email:</b> connect@fsc.org
            </p>
          </div>
        </>
      )}
      <div slot="modals">
        <CalciteModal
          fullscreen
          {...(termsOpen ? { open: true } : { open: undefined })}
          onClick={() => {
            setTermsOpen(false);
          }}
        >
          <p slot="header">Terms and Conditions</p>
          <iframe style={{ position: "absolute", height: "100%", width: "95%", border: "none"}}
            title="fsc_terms"
            slot="content"
            src="terms.html"
          ></iframe>
        </CalciteModal>
        <CalciteModal
          fullscreen
          {...(privacyOpen ? { open: true } : { open: undefined })}
          onClick={() => {
            setPrivacyOpen(false);
          }}
        >
          <p slot="header">Privacy Statement</p>
          <div slot="content">
          <iframe style={{ position: "absolute", height: "100%", width: "95%", border: "none"}}
            title="fsc_privacy"
            slot="content"
            src="privacy.html"
          ></iframe>
          </div>
        </CalciteModal>
        <CalciteModal
          fullscreen
          closeButtonDisabled
          {...(publicVisibleOpen ? { open: true } : { open: undefined })}
          onClick={() => {
            setPublicVisibleOpen(false);
          }}
        >
          <p slot="header">Public Visibility Statement</p>
          <iframe style={{ position: "absolute", height: "100%", width: "95%", border: "none"}}
            title="fsc_public"
            slot="content"
            src="public_vis.html"
          ></iframe>
           <CalciteButton slot="primary" onClick={()=>setPublicVisibleOpen(false)}>OK</CalciteButton>
          {/*<CalciteButton slot="secondary" onClick={()=>setPublicVisibleOpen(false)}>Cancel</CalciteButton> */}
        </CalciteModal>
        <CalciteModal title="Loading Shapefile" closeButtonDisabled {...(loadingVisible ? { open: true } : { open: undefined })}>
          <div slot="header">Loading Shapefile</div>
          <CalciteLoader slot="content" type="indeterminate" active ></CalciteLoader>
        </CalciteModal>
      </div>
      <CookieConsent
        location="bottom"
        buttonText="Allow"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#285c4d" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        We use cookies to give you the best possible experience. Some are essential for this site to function; others help us understand how you use the site, so we can improve it. We may also use targeted cookies for advertising purposes. Click “Allow” to proceed to the app.{" "}
        
      </CookieConsent>
    </CalciteShell>
  );
}

export default App;
