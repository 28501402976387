import esriRequest from "@arcgis/core/request.js";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import GroupLayer from "@arcgis/core/layers/GroupLayer.js";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";

/**
 *  Helper method to ensure that user supplied urls don't include whitespace or a trailing slash.
 */
export function cleanUrl(url) {
  // Guard so we don't try to trim something that's not a string
  if (typeof url !== "string") {
    return url;
  }
  // trim leading and trailing spaces, but not spaces inside the url
  url = url.trim();

  // remove the trailing slash to the url if one was included
  if (url[url.length - 1] === "/") {
    url = url.slice(0, -1);
  }
  return url;
}

/**
 * Ensure that a URL uses HTTPS. Adapted from the Dashboards team.
 */
export function enforceSsl(url) {
  return url.replace(/^http:\/\//i, "https://");
}

async function getLayerDetail(url) {
  const itemDataResponse = await esriRequest(url, {
    responseType: "json",
    query: { f: "json" },
  });

  return itemDataResponse.data;
}

export async function getLayerDetails(item, portal) {
  const itemDataResponse = await esriRequest(item.url, {
    responseType: "json",
    query: { f: "json" },
  });
  const itemData = itemDataResponse.data;

  let allLayerInfo = [];
  if (itemData.layers) {
    for (let i = 0; i < itemData.layers.length; i++) {
      let layerUrl = `${item.url}/${itemData.layers[i].id}`;
      const layerInfo = await getLayerDetail(layerUrl);
      layerInfo.url = layerUrl;
      layerInfo.selected = i === 0 ? true : false;
      allLayerInfo.push(layerInfo);
    }
  } else if (/MapServer\/\d+/.test(item.url)) {
    // maybe a mapservice hosted on ArcGIS Enteprise
    const layerInfo = await getLayerDetail(item.url);
    layerInfo.url = item.url;
    layerInfo.selected = true;
    allLayerInfo.push(layerInfo);
  }

  item.layers = allLayerInfo;

  return item;
}

export async function getFeaturesFromService({
  layer,
  whereClause,
  dataTable,
  maxRecordCount,
}) {
  // const fl = new FeatureLayer({
  //   url: params.url,
  // });

  const queryParams = layer.createQuery();
  queryParams.where = whereClause || "1=1";
  queryParams.returnGeometry = false;
  queryParams.outFields = [
    dataTable.geoidField,
    dataTable.valueField,
    ...dataTable.extraOutFields,
  ];
  queryParams.maxRecordCount = maxRecordCount;

  let allResults = [];
  let exceeds = true;
  let incrementer = 0;

  do {
    const response = await layer.queryFeatures(queryParams);
    exceeds = response.exceededTransferLimit;
    if (response.features.length > 0) {
      allResults = allResults.concat(response.features);
    }
    // recordCounter += incrementer + 1;
    incrementer += queryParams.maxRecordCount;
    queryParams.start = incrementer + 1;
    // console.log(queryParams.resultOffset);
    await new Promise((resolve) => setTimeout(resolve, 1000));
  } while (exceeds);

  // console.log(allResults);

  return allResults.map((f) => {
    let output = {
      attributes: {
        geoid: f.attributes[dataTable.geoidField],
        value: f.attributes[dataTable.valueField],
        indicator: dataTable.indicator,
        year: dataTable.year,
        category: dataTable.category,
        subcategory: dataTable.subcategory,
      },
    };
    dataTable.extraOutFields.forEach((of) => (output[of] = f.attributes[of]));
    return output;
  });
}

export async function addLayersToMap(view, item) {
  let layers = [];
  for (let i = item.layers.length - 1; i >= 0; i--) {
    const fl = new FeatureLayer({
      title: `${item.title} - ${item.layers[i].name}`,
      portalItem: {
        id: item.id,
      },
      layerId: item.layers[i].id,
      minScale: 0,
      maxScale: 0,
      visible: i === item.layers.length - 1 ? true : false,
    });

    reactiveUtils
      .once(() => fl.loadStatus === "loaded")
      .then(() => {
        // remove any nice popup
        // we just want the straight atts
        // if (!fl.popupTemplate) {
        fl.popupTemplate = fl.createPopupTemplate();
        // }
      });

    layers.push(fl);
  }

  const groupLayer = new GroupLayer({
    title: "Select a layer",
    visible: true,
    visibilityMode: "exclusive",
    layers: layers,
  });

  view.map.add(groupLayer);

  return layers;
}

function pad(number) {
  return number < 10 ? "0" + number : number;
}

function getFormattedDateTimeString() {
  // Helper function to pad numbers with a leading zero if necessary

  let date = new Date();

  let year = date.getFullYear();
  let month = pad(date.getMonth() + 1); // JavaScript counts months from 0 to 11.
  let day = pad(date.getDate());
  let hours = pad(date.getHours());
  let minutes = pad(date.getMinutes());
  let seconds = pad(date.getSeconds());

  let dateString = `${year}_${month}_${day}_${hours}${minutes}${seconds}`;

  return dateString;
}
